import { EquipmentRoutesContext } from "/apps/assets/building/equipment/routes"
import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { Loader, OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getSpaces, update } from "../api"
import { Equipment, EquipmentOverview } from "../models"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [nodes, setNodes] = useState([])
  const [gateways, setGateways] = useState([])
  const [spaces, setSpaces] = useState([])
  const [equipmentTypes, setEquipmentTypes] = useState([])
  const [equipmentSubTypes, setEquipmentSubTypes] = useState([])
  const [processing, setProcessing] = useState(false)
  const [loadingNodes, setLoadingNodes] = useState(false)
  const [loadingGateways, setLoadingGateways] = useState(false)
  const [loadingSpaces, setLoadingSpaces] = useState(false)
  const [loadingEquipments, setLoadingEquipments] = useState(false)
  const [loadingEquipmentSubTypes, setLoadingEquipmentSubTypes] = useState(false)
  const { equipment, retrieveDetail } = useContext(EquipmentRoutesContext)

  const updateObject = async function (e) {
    e.preventDefault()
    setProcessing(true)
    await update(siteStore.id, equipment.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Update", t("The equipment has been updated."))]))
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message

        if (error.response.data) {
          const errorsEquipment = error.response.data
          const errorsEquipCommInfo = {
            ...(errorsEquipment.gateway ? { gateway: errorsEquipment.gateway } : {}),
            ...(errorsEquipment.node ? { node: errorsEquipment.node } : {}),
            ...errorsEquipment.equip_comm_info
          }
          setErrors({
            ...errorsEquipment,
            ...{ equip_comm_info: errorsEquipCommInfo }
          })
        }
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setProcessing(false))
  }

  const retrieveNodes = async () => {
    setLoadingNodes(true)
    // TODO: remove when the api will no longer paginate
    await paginatedRequest(`asset/sites/${siteStore.id}/nodes/`, auth.userData.access_token)()
      .then(results => {
        setNodes(results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoadingNodes(false))

    // TODO: uncomment when the API will no longer paginate
    /* await getNodes(siteStore.id, auth.userData.access_token).then(response => {
      if (response.data.results) {
        setNodes(response.data.results)
      }
    }).catch(error => {
      dispatch(addNotifications([errorNotification("Error", error.message)]))
    }) */
  }

  const retrieveGateways = async () => {
    setLoadingGateways(true)
    // TODO: remove when the api will no longer paginate
    await paginatedRequest(`connectivity/sites/${siteStore.id}/gateways/`, auth.userData.access_token)()
      .then(results => {
        setGateways(results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoadingGateways(false))

    // TODO: uncomment when the API will no longer paginate
    /* await getGateways(siteStore.id, auth.userData.access_token).then(response => {
      if (response.data.results) {
        setGateways(response.data.results)
      }
    }).catch(error => {
      dispatch(addNotifications([errorNotification("Error", error.message)]))
    }) */
  }

  const retrieveSpaces = async () => {
    setLoadingSpaces(true)
    await getSpaces(siteStore.id, auth.userData.access_token)
      .then(({ data }) => {
        data.results && setSpaces(data.results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoadingSpaces(false))
  }

  const retrieveEquipmentTypes = async () => {
    setLoadingEquipments(true)
    // TODO: remove when the api will no longer paginate
    await paginatedRequest("asset/equipment_types/", auth.userData.access_token)()
      .then(results => {
        setEquipmentTypes(results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoadingEquipments(false))

    // TODO: uncomment when the API will no longer paginate
    /* await getEquipmentTypes(auth.userData.access_token).then(response => {
      if (response.data.results) {
        setEquipmentTypes(response.data.results)
      }
    }).catch(error => {
      dispatch(addNotifications([errorNotification("Error", error.message)]))
    }) */
  }

  const retrieveEquipmentSubTypes = async () => {
    setLoadingEquipmentSubTypes(true)
    // TODO: remove when the api will no longer paginate
    await paginatedRequest(`asset/equipment_subtypes/?equipment_type=${data.equipment_type}`, auth.userData.access_token)()
      .then(results => {
        setEquipmentSubTypes(results)
        setData({
          ...data,
          ...{ equipment_subtype: null }
        })
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoadingEquipmentSubTypes(false))

    // TODO: uncomment when the API will no longer paginate
    /* await getEquipmentSubTypes(data.equipment_type, auth.userData.access_token).then(response => {
      if (response.data.results) {
        setEquipmentSubTypes(response.data.results)
        setData({
          ...data,
          ...{ equipment_subtype: null},
        })
      }
    }).catch(error => {
      dispatch(addNotifications([errorNotification("Error", error.message)]))
    }) */
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    equipment && setLoading(false)
  }, [equipment])

  useEffect(() => {
    if (siteStore.id && equipment && equipment.id) {
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/map/` },
          { label: t("Sites"), url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          { label: t("Assets"), url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/` },
          {
            label: t("Building"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/equipments/`
          },
          {
            label: t("Equipments"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/equipments/`
          },
          {
            label: equipment.label ?? equipment.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/equipments/${equipment.id}/`
          }
        ])
      )
    }
  }, [siteStore.id, equipment])

  useEffect(() => {
    if (edit) {
      retrieveNodes()
      retrieveGateways()
      retrieveSpaces()
      retrieveEquipmentTypes()
    }
  }, [edit])

  useEffect(() => {
    edit && data.equipment_type ? retrieveEquipmentSubTypes() : setEquipmentSubTypes([])
  }, [edit, data.equipment_type])

  return (
    <>
      {equipment ? (
        <>
          <div className="base-content-title">
            <h2>{equipment.name}</h2>
          </div>

          <OverView
            object={equipment}
            model={Equipment(nodes, gateways, spaces, equipmentTypes, equipmentSubTypes, loadingNodes, loadingGateways, loadingSpaces, loadingEquipments, loadingEquipmentSubTypes)}
            overviewModel={EquipmentOverview()}
            updateObject={updateObject}
            data={data}
            setData={setData}
            errors={errors}
            edit={edit}
            loading={loading}
            processing={processing || loadingNodes || loadingGateways || loadingSpaces || loadingEquipments || loadingEquipmentSubTypes}
            setEdit={setEdit}
            deleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/equipments/${equipment.id}/delete/`}
          />
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}

import axios from "axios"

function header(token) {
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

export async function retrieveList(queryParams, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/parameters/${queryParams}`, header(token))
}

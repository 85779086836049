import { TabsLayout } from "@software-engineering/hivolution-frontend-utils"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Route, Routes, useLocation } from "react-router-dom"

import AggregatorRoutes from "./aggregator/routes"
import DistributorRoutes from "./distributor/routes"
import RetailerRoutes from "./retailer/routes"
import TransmissionRoutes from "./transmission/routes"

export default function EnergyCompanyRoutes() {
  /* Tabs to ListLayout component*/
  const location = useLocation()
  const { t } = useTranslation(["configuration"])
  const [tabs, setTabs] = useState([
    {
      name: t("Distributors"),
      active: location.pathname.includes("distributors"),
      url: "/configuration/energy-company/distributors/"
    },
    {
      name: t("Aggregators"),
      active: location.pathname.includes("aggregators"),
      url: "/configuration/energy-company/aggregators/"
    },
    {
      name: t("Retailers"),
      active: location.pathname.includes("retailers"),
      url: "/configuration/energy-company/retailers/"
    },
    {
      name: t("TSO"),
      active: location.pathname.includes("transmissions"),
      url: "/configuration/energy-company/transmissions/"
    }
  ])

  return (
    <Routes>
      <Route
        element={
          <TabsLayout
            title={t("Energy companies")}
            tabs={tabs}
            setTabs={setTabs}
          />
        }
      >
        <Route
          index
          path="distributors/*"
          element={<DistributorRoutes />}
        />
        <Route
          path="aggregators/*"
          element={<AggregatorRoutes />}
        />
        <Route
          path="retailers/*"
          element={<RetailerRoutes />}
        />
        <Route
          path="transmissions/*"
          element={<TransmissionRoutes />}
        />
      </Route>
    </Routes>
  )
}

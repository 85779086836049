import axios from "axios"

function header(token) {
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

export async function retrieveList(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/operation/sites/${siteId}/constraints/`, header(token))
}

export async function retrieve(siteId, id, token) {
  return await axios.get(`${window.BACKEND_URL}/operation/sites/${siteId}/constraints/${id}/`, header(token))
}

export async function add(siteId, data, token) {
  return await axios.post(`${window.BACKEND_URL}/operation/sites/${siteId}/constraints/`, data, header(token))
}

export async function update(siteId, id, data, token) {
  return await axios.put(`${window.BACKEND_URL}/operation/sites/${siteId}/constraints/${id}/`, data, header(token))
}

export async function remove(siteId, id, token) {
  return await axios.delete(`${window.BACKEND_URL}/operation/sites/${siteId}/constraints/${id}/`, header(token))
}

export async function removeSpaceConstraints(siteId, ids, token) {
  return await axios.post(`${window.BACKEND_URL}/operation/sites/${siteId}/constraints/bulk-delete/`, ids, header(token))
}

export async function getCount(id, token) {
  return await axios.get(`${window.BACKEND_URL}/DETAIL_URL/${id}/count`, header(token))
}

export async function retrieveSpaces(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/spaces/`, header(token))
}

import { retrieveList } from "/apps/energy/energy-delivery-point/api"
import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { errorNotification } from "/utils/notification"
import { buildQueryParams } from "/utils/queryparams"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { SortOrder } from "primereact/api"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [count, setCount] = useState(0)

  const retrieve = async (queryParams = `?limit=${limit}&offset=${offset}`) => {
    setLoading(true)
    await retrieveList(siteStore.id, queryParams, auth.userData.access_token)
      .then(({ data }) => {
        setCount(data.count)
        data.results && setItems(data.results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoading(false))
  }

  const columns = [
    { field: "meter_id", header: t("Meter ID"), sortable: true },
    { field: "energy_type", header: t("Energy type"), sortable: true },
    { field: "energy_company_name", header: t("Distributor"), sortable: true },
    { field: "start_validity", header: t("Start validity"), sortable: true },
    { field: "end_validity", header: t("End validity"), sortable: true }
  ]

  const getDetailUrl = edpId => `/organizations/${organizationStore.id}/sites/${siteStore.id}/energy/energy-delivery-points/${edpId}/`

  const getDeleteUrl = edpId => `/organizations/${organizationStore.id}/sites/${siteStore.id}/energy/energy-delivery-points/${edpId}/delete/`

  const onRowClick = edpId => {
    navigate(getDetailUrl(edpId))
  }

  const menuFilters = { items, columns, filters, setFilters }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
    siteStore.id &&
      organizationStore.id &&
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/` },
          { label: t("Sites"), url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          {
            label: t("Energy delivery points"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/energy/energy-delivery-points/`
          }
        ])
      )
  }, [siteStore.id, organizationStore.id])

  useEffect(() => {
    retrieve(buildQueryParams(limit, offset))
  }, [limit, offset])

  return (
    <>
      <section className="list-section">
        <ListActions filters={menuFilters}>
          <Link to={`/organizations/${organizationStore.id}/sites/${siteStore.id}/energy/energy-delivery-points/create/`}>
            <Button
              rounded
              severity="info"
              icon="fa-regular fa-plus"
              label={t("Create")}
            />
          </Link>
        </ListActions>

        <ListLayout
          columns={columns}
          value={items}
          sortField="meter_id"
          sortOrder={SortOrder.ASC}
          objectName={t("Energy delivery points")}
          loading={loading}
          filters={filters}
          getDetailUrl={getDetailUrl}
          getDeleteUrl={getDeleteUrl}
          onRowClick={onRowClick}
          offset={offset}
          limit={limit}
          setOffset={setOffset}
          setLimit={setLimit}
          count={count}
        />
      </section>
    </>
  )
}

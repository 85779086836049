import axios from "axios"

function header(token) {
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

function headerFile(token) {
  return {
    headers: {
      "Content-Type": "multipart/form-data; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

export async function retrieveList(siteId, queryParams, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/equipments/${queryParams}`, header(token))
}

export async function retrieve(siteId, equimentId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/equipments/${equimentId}/`, header(token))
}

export async function add(siteId, data, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/equipments/`, data, header(token))
}

export async function update(siteId, equimentId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/asset/sites/${siteId}/equipments/${equimentId}/`, data, header(token))
}

export async function remove(siteId, equimentId, token) {
  return await axios.delete(`${window.BACKEND_URL}/asset/sites/${siteId}/equipments/${equimentId}/`, header(token))
}

export async function getNodes(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/nodes/`, header(token))
}

export async function getGateways(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/sites/${siteId}/gateways/`, header(token))
}

export async function getSpaces(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/spaces/`, header(token))
}

export async function getEquipmentTypes(token) {
  return await axios.get(`${window.BACKEND_URL}/asset/equipment_types/`, header(token))
}

export async function getEquipmentSubTypes(equipmentTypeId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/equipment_subtypes/?equipment_type=${equipmentTypeId}`, header(token))
}

export async function getEquipmentsCount(ids, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/equipments/count/`, ids, header(token))
}

export async function removeEquipments(siteId, ids, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/equipments/deletion/`, ids, header(token))
}

export async function getAllValues(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/equipments/allvalues`, header(token))
}

export async function exportEquipments(siteId, token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/asset/sites/${siteId}/equipments/bulk/export`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function exportTemplate(token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/asset/sites/0/equipments/bulk/export`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function importEquipments(siteId, file, token) {
  let formData = new FormData()
  formData.append("file", file)
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/equipments/bulk/import`, formData, headerFile(token), { signal: new AbortController() })
}

import { PROTOCOL_INSTANCE_TYPE } from "/utils/constants/protocol-instance"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { GatewayRoutesContext } from "../../../gateway/routes"
import { ConnectorInstanceRoutesContext } from "../../routes"
import { remove } from "../api"
import { ProtocolInstanceRouteContext } from "../routes"

export default function Delete() {
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["assets"])
  const { gateway } = useContext(GatewayRoutesContext)
  const { connector } = useContext(ConnectorInstanceRoutesContext)
  const { protocolInstance } = useContext(ProtocolInstanceRouteContext)
  const [processing, setProcessing] = useState(false)

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const removeObject = async () => {
    setProcessing(true)
    await remove(siteStore.id, protocolInstance.id, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification("Protocol instance", t("The protocol instance has been removed."))])
        navigate(`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/protocol-instances/`)
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 400 && error.response.data.id) {
          displayNotification([errorNotification("Protocol instance", error.response.data.id[0])])
        } else {
          const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
          displayNotification([errorNotification("Protocol instance", message)])
        }
      })
      .finally(() => setProcessing(true))
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id &&
      gateway &&
      gateway.id &&
      connector &&
      connector.id &&
      protocolInstance &&
      protocolInstance.id &&
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/` },
          { label: t("Sites"), url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          { label: t("Assets"), url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/` },
          {
            label: t("Gateways"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/`
          },
          {
            label: gateway.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/`
          },
          {
            label: t("Connector instances"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/`
          },
          {
            label: connector.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/`
          },
          {
            label: t("Protocol instances"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/protocol-instances/`
          },
          {
            label: `${protocolInstance.type} – ${protocolInstance.gateway_ip_interface.label}`,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/protocol-instances/${protocolInstance.id}/`
          },
          {
            label: t("Delete"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/protocol-instances/${protocolInstance.id}/delete/`
          }
        ])
      )
  }, [siteStore.id, gateway, connector, protocolInstance])

  return (
    <>
      {protocolInstance && (
        <DeleteConfirmation
          objectType={t("Protocol instance")}
          objectNames={[`${PROTOCOL_INSTANCE_TYPE[protocolInstance.type]}`]}
          remove={removeObject}
          processing={processing}
          goBack={() => navigate(-1)}
        />
      )}
    </>
  )
}

import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"

import { InnerTabView, OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { TabPanel } from "primereact/tabview"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getSpaces, update } from "../api"
import { BacnetNode, BacnetOverview } from "../models/bacnet"
import { CarelNode, CarelOverview } from "../models/carel"
import { CarelNetworkNode, CarelNetworkOverview } from "../models/carelnetwork"
import { ExternalAPIOverview } from "../models/externalapi"
import { ModbusNode, ModbusOverview } from "../models/modbus"
import { OPCUANode, OPCUAOverview } from "../models/opcua"
import { SMNode, SMOverview } from "../models/sm"
import { SMControllerNode, SMControllerOverview } from "../models/smcontroller"
import { SMIONode, SMIOOverview } from "../models/smio"
import { SMMeterNode, SMMeterOverview } from "../models/smmeter"
import { NodeRoutesContext } from "../routes"
import Interfaces from "./Interfaces"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [object, setObject] = useState(null)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [spaces, setSpaces] = useState([])
  const [smNodes, setSMNodes] = useState([])
  const [carelNodes, setCarelNodes] = useState([])
  const { node, retrieveDetail } = useContext(NodeRoutesContext)

  const updateObject = async function (e) {
    e.preventDefault()
    setProcessing(true)
    await update(siteStore.id, node.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Automation node", t("The automation node has been updated."))]))
        setLoading(true)
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Automation node", message)]))
      })
      .finally(() => setProcessing(false))
  }

  const getModel = nodeType => {
    switch (nodeType) {
      case "modbusnode":
        return ModbusNode(spaces)
      case "bacnetnode":
        return BacnetNode(spaces)
      case "smnode":
        return SMNode(spaces)
      case "smionode":
        return SMIONode(spaces, smNodes)
      case "smcontrollernode":
        return SMControllerNode(spaces, smNodes)
      case "smmeternode":
        return SMMeterNode(spaces, smNodes)
      case "opcuanode":
        return OPCUANode(spaces)
      case "carelnode":
        return CarelNode(spaces)
      case "carelnetworknode":
        return CarelNetworkNode(spaces, carelNodes)
      default:
        return {}
    }
  }

  const getOverviewModel = nodeType => {
    switch (nodeType) {
      case "modbusnode":
        return ModbusOverview()
      case "bacnetnode":
        return BacnetOverview()
      case "smnode":
        return SMOverview()
      case "smionode":
        return SMIOOverview()
      case "smcontrollernode":
        return SMControllerOverview()
      case "smmeternode":
        return SMMeterOverview()
      case "opcuanode":
        return OPCUAOverview()
      case "carelnode":
        return CarelOverview()
      case "carelnetworknode":
        return CarelNetworkOverview()
      case "externalapinode":
        return ExternalAPIOverview()
      default:
        return {}
    }
  }

  useEffect(() => {
    if (node) {
      setLoading(false)
      setObject(node)
    }
  }, [node])

  useEffect(() => {
    const retrieveSMNodes = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest(`asset/sites/${siteStore.id}/nodes/?type=smnode`, auth.userData.access_token)()
        .then(results => {
          setSMNodes(results)
          if (object.sm_node) {
            const smNode = results.find(node => node.id === object.sm_node)
            const obj = object
            obj.sm_node_label = smNode.name
            setObject(obj)
          }
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await getSMNodes(siteId, auth.userData.access_token).then(response => {
        if (response.data.results) {
          setSMNodes(response.data.results)
        }
      }).catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }

    const retrieveCarelNodes = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest(`asset/sites/${siteStore.id}/nodes/?type=carelnode`, auth.userData.access_token)()
        .then(results => {
          setCarelNodes(results)
          if (object.carel_node) {
            const carelNode = results.find(node => node.id === object.carel_node)
            const obj = object
            obj.carel_node_label = carelNode.name
            setObject(obj)
          }
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })

      // TODO: uncomment when the API will no longer paginate
      /* await getCarelNodes(siteId, auth.userData.access_token).then(response => {
        if (response.data.results) {
          setCaremNodes(response.data.results)
        }
      }).catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      }) */
    }

    const retrieveSpaces = async () => {
      await getSpaces(siteStore.id, auth.userData.access_token)
        .then(({ data }) => {
          setSpaces(data.results)
          if (object.space) {
            const space = response.data.find(item => item.id === object.space)
            const obj = object
            obj.space_label = space.name
            setObject(obj)
          }
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification("Error", error.message)]))
        })
    }

    if (siteStore.id && object && ["smionode", "smcontrollernode", "smmeternode"].includes(object.type)) retrieveSMNodes()
    else setSMNodes([])

    if (siteStore.id && object && object.type === "carelnetworknode") retrieveCarelNodes()
    else setCarelNodes([])

    if (siteStore.id && object) retrieveSpaces()
  }, [siteStore.id, object])

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id &&
      object &&
      object.id &&
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/` },
          { label: t("Sites"), url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          { label: t("Assets"), url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/` },
          {
            label: t("Communication"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/networks/`
          },
          {
            label: t("Automation nodes"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/automation-nodes/`
          },
          {
            label: object.label ?? object.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/automation-nodes/${object.id}/`
          }
        ])
      )
  }, [siteStore.id, object])

  return (
    <>
      {object && siteStore.id && (
        <>
          <div className="base-content-title">
            <h2>
              {object.type} – {object.name}
            </h2>
          </div>
          <InnerTabView>
            <TabPanel header={t("Overview")}>
              <OverView
                object={object}
                model={data && getModel(data.type)}
                overviewModel={object && getOverviewModel(object.type)}
                updateObject={updateObject}
                data={data}
                setData={setData}
                errors={errors}
                edit={edit}
                loading={loading}
                processing={processing}
                setEdit={setEdit}
                deleteUrl={`/sites/${siteStore.id}/assets/communication/automation-nodes/${node.id}/delete/`}
              />
            </TabPanel>
            <TabPanel header={t("Network interfaces")}>
              <Interfaces
                siteId={siteStore.id}
                nodeId={object.id}
                node={object}
              />
            </TabPanel>
          </InnerTabView>
        </>
      )}
    </>
  )
}

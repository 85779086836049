import axios from "axios"

const header = token => ({
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "Authorization": `Bearer ${token}`
  }
})

export async function retrieveList(siteId, queryParams, token) {
  return await axios.get(`${window.BACKEND_URL}/operation/sites/${siteId}/unavailability_categories/jira/${queryParams}`, header(token))
}

export async function retrieve(siteId, unavailabilityCategoryId, token) {
  return await axios.get(`${window.BACKEND_URL}/operation/sites/${siteId}/unavailability_categories/jira/${unavailabilityCategoryId}/`, header(token))
}

export async function add(siteId, data, token) {
  return await axios.post(`${window.BACKEND_URL}/operation/sites/${siteId}/unavailability_categories/jira/`, data, header(token))
}

export async function update(siteId, unavailabilityCategoryId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/operation/sites/${siteId}/unavailability_categories/jira/${unavailabilityCategoryId}/`, data, header(token))
}

export async function remove(siteId, unavailabilityCategoryId, token) {
  return await axios.delete(`${window.BACKEND_URL}/operation/sites/${siteId}/unavailability_categories/jira/${unavailabilityCategoryId}/`, header(token))
}

export async function getCount(siteId, unavailabilityCategoryId, token) {
  return await axios.get(`${window.BACKEND_URL}/operation/sites/${siteId}/unavailabilities/${unavailabilityCategoryId}/count/`, header(token))
}

export async function retrieveSpaces(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/spaces/`, header(token))
}

export async function removeCategories(siteId, ids, token) {
  return await axios.post(`${window.BACKEND_URL}/operation/sites/${siteId}/unavailability_categories/bulk-delete/`, ids, header(token))
}

export async function getCategoriesCount(siteId, ids, token) {
  return await axios.post(`${window.BACKEND_URL}/operation/sites/${siteId}/unavailability_categories/multiple-count/`, ids, header(token))
}

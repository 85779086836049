import i18n from "i18next"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"

import { commonsFields, commonsOverview } from "./commons"

export const BacnetNode = spaces => {
  return {
    ...commonsFields(spaces),
    ...{
      device_instance: {
        label: i18n.t("Device instance", { ns: "assets" }),
        field: InputText,
        options: {}
      },
      router: {
        label: i18n.t("Router", { ns: "assets" }),
        field: InputSwitch,
        options: {}
      }
    }
  }
}

export const BacnetOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      device_instance: { label: i18n.t("Device instance", { ns: "assets" }) },
      router: { label: i18n.t("Router", { ns: "assets" }) }
    }
  }
}

import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { errorNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { BaseLayout, ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { retrieveList } from "../api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const organizationStore = useSelector(organization)
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation(["assets"])
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [filters, setFilters] = useState({})
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [count, setCount] = useState(0)

  const retrieve = async (queryParams = `?limit=${limit}&offset=${offset}&organization_id=${organizationStore.id}`) => {
    await retrieveList(queryParams, auth.userData.access_token)
      .then(({ data }) => {
        data.results && setItems(data.results)
        setCount(data.count)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    organizationStore.id &&
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/map/` },
          { label: t("Sites"), url: `/organizations/${organizationStore.id}/sites` }
        ])
      )
  }, [organizationStore.id])

  useEffect(() => {
    retrieve()
  }, [limit, offset])

  const columns = [
    { field: "pretty_name", header: "Pretty name", sortable: true },
    { field: "name", header: "Name", sortable: true },
    { field: "country", header: "Country", sortable: true }
  ]

  const getDetailUrl = siteId => {
    return `/organizations/${organizationStore.id}/sites/${siteId}/`
  }

  const getDeleteUrl = siteId => {
    return `/organizations/${organizationStore.id}/sites/${siteId}/delete/`
  }

  const onRowClick = siteId => {
    navigate(getDetailUrl(siteId))
  }

  const menuFilters = { columns, items, filters, setFilters }

  return (
    <BaseLayout title={t("Sites")}>
      <>
        <ListActions filters={menuFilters}>
          <Link to={`/organizations/${organizationStore.id}/sites/create/`}>
            <Button
              rounded
              severity="info"
              icon="fa-regular fa-plus"
              label={t("Create site")}
            />
          </Link>
        </ListActions>

        <ListLayout
          columns={columns}
          value={items}
          objectName={t("Site")}
          filters={filters}
          sortField="pretty_name"
          getDetailUrl={getDetailUrl}
          getDeleteUrl={getDeleteUrl}
          onRowClick={onRowClick}
          loading={loading}
          limit={limit}
          offset={offset}
          setLimit={setLimit}
          setOffset={setOffset}
          count={count}
        />
      </>
    </BaseLayout>
  )
}

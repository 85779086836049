{
  "0: digital input": "0: digital input",
  "1: digital output": "1: digital output",
  "2: analog input": "2: analog input",
  "3: analog output": "3: analog output",
  "Acceptable values": "Acceptable values",
  "Access level": "Access level",
  "Action": "Action",
  "Active": "Active",
  "Add": "Add",
  "Add values": "Add values",
  "Address": "Address",
  "Aggregator Response": "Aggregator Response",
  "Altitude": "Altitude",
  "An error occured during the import, see more details in the error report file": "An error occured during the import, see more details in the error report file.",
  "Are you sure you want to delete this interface?": "Are you sure you want to delete this interface?",
  "Assets": "Assets",
  "Automation node": "Automation node",
  "Automation nodes": "Automation nodes",
  "Automation point": "Automation point",
  "Automation point type": "Automation point type",
  "Automation points": "Automation points",
  "Back to edge controller list": "Back to edge controller list",
  "Back to state list": "Back to state list",
  "Bacnet": "Bacnet",
  "Bacnet network": "Bacnet network",
  "Bacnet number": "Bacnet number",
  "Bacnet priority": "Bacnet priority",
  "Bacnet priority uninhibition": "Bacnet priority uninhibition",
  "Bacnet property": "Bacnet property",
  "Base informations": "Base informations",
  "Batch size": "Batch size",
  "Batch size read": "Batch size read",
  "Batch size write": "Batch size write",
  "Bit start": "Bit start",
  "Boolean": "Boolean",
  "Building": "Building",
  "Cabinet": "Cabinet",
  "Cancel": "Cancel",
  "Carel node": "Carel node",
  "Carel Nodes": "Carel Nodes",
  "Choose a node type": "Choose a node type",
  "Choose a sub type": "Choose a sub type",
  "Choose a type": "Choose a type",
  "Cid": "Cid",
  "Circuit breakers": "Circuit breakers",
  "City": "City",
  "Coef": "Coef",
  "Coil": "Coil",
  "Comment": "Comment",
  "Communicable": "Communicable",
  "Communication": "Communication",
  "Condition": "Condition",
  "Configuration": "Configuration",
  "Confirm password": "Confirm password",
  "Confirmed curtail schedule": "Confirmed curtail schedule",
  "Confirmed curtail schedules": "Confirmed curtail schedules",
  "Connect": "Connect",
  "Connect a network": "Connect a network",
  "Connectivity": "Connectivity",
  "Connectivity view": "Connectivity view",
  "Connector instance": "Connector instance",
  "Connector instances": "Connector instances",
  "Connector instances has been removed": "Connector instances has been removed.",
  "Constraint states": "Constraint states",
  "Contract": "Contract",
  "Contract type": "Contract type",
  "Contracts": "Contracts",
  "Cooled": "Cooled",
  "Country": "Country",
  "Create": "Create",
  "Create automation node": "Create automation node",
  "Create automation point": "Create automation point",
  "Create connector instance": "Create connector instance",
  "Create contract": "Create contract",
  "Create curcuit breaker": "Create curcuit breaker",
  "Create curtail parameter": "Create curtail parameter",
  "Create curtail parameters": "Create curtail parameters",
  "Create edge controller": "Create edge controller",
  "Create energy delivery point": "Create energy delivery point",
  "Create equipment": "Create equipment",
  "Create flex opti": "Create flex opti",
  "Create gateway": "Create gateway",
  "Create load curtail schedule": "Create load curtail schedule",
  "Create network": "Create network",
  "Create point": "Create point",
  "Create proposal for aggregator": "Create proposal for aggregator",
  "Create site": "Create site",
  "Create space": "Create space",
  "Create space constraint": "Create space constraint",
  "Create state": "Create state",
  "Create store": "Create store",
  "Create unavailability category": "Create unavailability category",
  "Created at": "Created at",
  "Curtail capacity": "Curtail capacity",
  "Curtail end": "Curtail end",
  "Curtail parameters": "Curtail parameters",
  "Curtail parameters have been created": "Curtail parameters have been created.",
  "Curtail parameters have been removed": "Curtail parameters have been removed.",
  "Curtail parameters have been updated": "Curtail parameters have been updated.",
  "Curtail start": "Curtail start",
  "Data type": "Data type",
  "Delete": "Delete",
  "Delivery points": "Delivery points",
  "Deploy": "Deploy",
  "Deployed": "Deployed",
  "Description": "Description",
  "Detail": "Detail",
  "Details": "Details",
  "Device ID": "Device ID",
  "Device instance": "Device instance",
  "Device type": "Device type",
  "Disable storage": "Disable storage",
  "Discrete input": "Discrete input",
  "Distributor": "Distributor",
  "Double": "Double",
  "Download": "Download",
  "Edge blocks": "Edge blocks",
  "Edge controller": "Edge controller",
  "Edge controller history": "Edge controller history",
  "Edge controllers": "Edge controllers",
  "Edge memory objects": "Edge memory objects",
  "Electrical": "Electrical",
  "End": "End",
  "End date": "End date",
  "End time": "End time",
  "End validity": "End validity",
  "Energy": "Energy",
  "Energy delivery point": "Energy delivery point",
  "Energy delivery points": "Energy delivery points",
  "Energy type": "Energy type",
  "Equipment": "Equipment",
  "Equipment sub type": "Equipment sub type",
  "Equipment type": "Equipment type",
  "Equipments": "Equipments",
  "Equipments has been removed": "Equipments has been removed.",
  "Equipments powered": "Equipments powered",
  "Equivalent metering": "Equivalent metering",
  "Equivalent meterings": "Equivalent meterings",
  "Error loading organizations": "Error loading organizations",
  "Error loading price zones": "Error loading price zones",
  "Exceed duration": "Exceed duration",
  "Export": "Export",
  "External ID": "External ID",
  "Flex contract": "Flex contract",
  "Flex opti": "Flex opti",
  "Flexibility": "Flexibility",
  "Float": "Float",
  "Forced stop hours": "Forced stop hours",
  "Forced stop reset": "Forced stop reset",
  "Format": "Format",
  "Gateway": "Gateway",
  "Gateway interface": "Gateway interface",
  "Gateway interfaces": "Gateway interfaces",
  "Gateway point": "Gateway point",
  "Gateway point text": "Gateway point text",
  "Gateway point value": "Gateway point value",
  "Gateways": "Gateways",
  "Gauge": "Gauge",
  "Hardware": "Hardware",
  "Heated": "Heated",
  "Height (m)": "Height (m)",
  "Historical load curtailment for": "Historical load curtailment for",
  "History": "History",
  "Holding register": "Holding register",
  "HTTP": "HTTP",
  "HTTPS": "HTTPS",
  "ID Var": "ID Var",
  "Import": "Import",
  "Infos": "Infos",
  "Inhibit states": "Inhibit states",
  "Initial values": "Initial values",
  "Input register": "Input register",
  "Int": "Int",
  "Int{{value}}": "Int{{value}}",
  "IO node type": "IO node type",
  "IP": "IP",
  "IP addres range": "IP addres range",
  "IP address": "IP address",
  "IP Address": "IP Address",
  "IP addresses": "IP addresses",
  "IP Range": "IP Range",
  "Is Logic": "Is Logic",
  "Latitude": "Latitude",
  "Line ID": "Line ID",
  "Linear constant": "Linear constant",
  "Linear multiplier": "Linear multiplier",
  "Load curtail parameters": "Load curtail parameters",
  "Load curtail schedule": "Load curtail schedule",
  "Load curtailment for": "Load curtailment for",
  "Longitude": "Longitude",
  "Low cipher": "Low cipher",
  "Lower bound": "Lower bound",
  "Manage modbus response timeouts": "Manage modbus response timeouts",
  "Manage timeout": "Manage timeout",
  "Manufacturer": "Manufacturer",
  "Market player": "Market player",
  "Market player interaction": "Market player interaction",
  "Market schedule group proposal history": "Market schedule group proposal history",
  "Meter ID": "Meter ID",
  "Mod": "Mod",
  "Model": "Model",
  "MSTP address": "MSTP address",
  "Multi-case name": "Multi-case name",
  "Name": "Name",
  "Namespace index": "Namespace index",
  "Network": "Network",
  "Network interface": "Network interface",
  "Network interfaces": "Network interfaces",
  "Networks": "Networks",
  "Networks has been removed": "Networks has been removed.",
  "No": "No",
  "Node": "Node",
  "Node address": "Node address",
  "Node ID": "Node ID",
  "Node interfaces": "Node interfaces",
  "Nodes has been removed": "Nodes has been removed.",
  "Nominal intensity": "Nominal intensity",
  "Num suction": "Num suction",
  "Number of Bits": "Number of Bits",
  "Number of points": "Number of points",
  "Number of stores": "Number of stores",
  "Number of words": "Number of words",
  "Object instance": "Object instance",
  "Object name": "Object name",
  "Object type": "Object type",
  "OBJECT_NAME": "OBJECT_NAME",
  "Operations": "Operations",
  "Optimization": "Optimization",
  "Optimization gateways": "Optimization gateways",
  "Optimized": "Optimized",
  "Organization": "Organization",
  "Original point value": "Original point value",
  "Overview": "Overview",
  "Parameter": "Parameter",
  "Parent circuit breaker": "Parent circuit breaker",
  "Parent space": "Parent space",
  "Password": "Password",
  "Passwords must be the same": "Passwords must be the same",
  "Path": "Path",
  "Percent of share economics": "Percent of share economics",
  "Percentage of share economics": "Percentage of share economics",
  "Period": "Period",
  "Permeable": "Permeable",
  "Point": "Point",
  "Points": "Points",
  "Points has been removed": "Points has been removed.",
  "Points optimized": "Points optimized",
  "Points writable": "Points writable",
  "Port": "Port",
  "Present value": "Present value",
  "Pretty name": "Pretty name",
  "Price zone": "Price zone",
  "Priority": "Priority",
  "Priority {{priority}}": "Priority {{priority}}",
  "Priority array": "Priority array",
  "Proposal for aggregator": "Proposal for aggregator",
  "Proposal sent": "Proposal sent",
  "Proposed by": "Proposed by",
  "Proposed curtail schedule": "Proposed curtail schedule",
  "Proposed curtail schedules": "Proposed curtail schedules",
  "Proposed time": "Proposed time",
  "Protocol": "Protocol",
  "Protocol instance": "Protocol instance",
  "Protocol instances": "Protocol instances",
  "Protocol instances has been removed": "Protocol instances has been removed.",
  "Protocol type": "Protocol type",
  "Protocols": "Protocols",
  "Pulling interval": "Pulling interval",
  "Rack ID": "Rack ID",
  "Read": "Read",
  "Read / Write": "Read / Write",
  "Relinquish default": "Relinquish default",
  "Request time": "Request time",
  "Requested by": "Requested by",
  "Requested?": "Requested?",
  "Response timeout": "Response timeout",
  "Response timeouts has been updated": "Response timeouts has been updated",
  "Router": "Router",
  "Safe states": "Safe states",
  "Save": "Save",
  "Schedule date": "Schedule date",
  "Scheme": "Scheme",
  "Scope": "Scope",
  "Security mode": "Security mode",
  "Security policy": "Security policy",
  "Select a price zone": "Select a price zone",
  "Select a weather zone": "Select a weather zone",
  "Select an organizations": "Select an organizations",
  "Send to aggregator": "Send to aggregator",
  "Sent by": "Sent by",
  "Serial": "Serial",
  "Serial ID": "Serial ID",
  "Server certificate path": "Server certificate path",
  "Site": "Site",
  "Sites": "Sites",
  "Slave ID": "Slave ID",
  "Sleep time": "Sleep time",
  "SM Nodes": "SM Nodes",
  "SM Type": "SM Type",
  "Software": "Software",
  "Space": "Space",
  "Space constraint": "Space constraint",
  "Space constraint detail": "Space constraint detail",
  "Space constraint details": "Space constraint details",
  "Space constraints": "Space constraints",
  "Space constraints has been removed": "Space constraints has been removed.",
  "Spaces": "Spaces",
  "Start": "Start",
  "Start date": "Start date",
  "Start time": "Start time",
  "Start validity": "Start validity",
  "State": "State",
  "State history": "State history",
  "State name": "State name",
  "State values": "State values",
  "States": "States",
  "Status": "Status",
  "Status flags": "Status flags",
  "Store": "Store",
  "Store type": "Store type",
  "Stores": "Stores",
  "Stores has been removed": "Stores has been removed.",
  "String": "String",
  "Subtype": "Subtype",
  "Suction ID": "Suction ID",
  "Summary for": "Summary for",
  "Surface area (m²)": "Surface area (m²)",
  "Swap bytes": "Swap bytes",
  "Swap words": "Swap words",
  "Synchronize legacy": "Synchronize legacy",
  "System manager": "System manager",
  "Tag": "Tag",
  "Target": "Target",
  "Template": "Template",
  "The automation node has been created": "The automation node has been created.",
  "The automation node has been removed": "The automation node has been removed.",
  "The automation node has been updated": "The automation node has been updated.",
  "The automation point has been created": "The automation point has been created.",
  "The automation point has been removed": "The automation point has been removed.",
  "The automation point has been updated": "The automation point has been updated.",
  "The circuit breaker has been created": "The circuit breaker has been created.",
  "The connector instance has been created": "The connector instance has been created.",
  "The connector instance has been removed": "The connector instance has been removed.",
  "The connector instance has been updated": "The connector instance has been updated.",
  "The contract has been created": "The contract has been created.",
  "The contract has been removed": "The contract has been removed.",
  "The contract has been updated": "The contract has been updated.",
  "The curtail schedule has been removed": "The curtail schedule has been removed",
  "The curtail schedule has been updated": "The curtail schedule has been updated.",
  "The edge controller has been created": "The edge controller has been created.",
  "The edge controller has been removed": "The edge controller has been removed.",
  "The edge controller has been updated": "The edge controller has been updated.",
  "The energy delivery point has been created": "The energy delivery point has been created.",
  "The energy delivery point has been removed": "The energy delivery point has been removed.",
  "The energy delivery point has been updated": "The energy delivery point has been updated.",
  "The equipment has been created": "The equipment has been created.",
  "The equipment has been removed": "The equipment has been removed.",
  "The equipment has been updated": "The equipment has been updated.",
  "The flex contract has been created": "The flex contract has been created.",
  "The flex opti has been created": "The flex opti has been created.",
  "The flex opti has been removed": "The flex opti has been removed.",
  "The flex opti has been updated": "The flex opti has been updated.",
  "The gateway has been created": "The gateway has been created.",
  "The gateway has been removed": "The gateway has been removed.",
  "The gateway has been updated": "The gateway has been updated.",
  "The gateway interface has been created": "The gateway interface has been created.",
  "The gateway interface has been removed": "The gateway interface has been removed.",
  "The interface has been created": "The interface has been created.",
  "The interface has been removed": "The interface has been removed.",
  "The interface has been updated": "The interface has been updated.",
  "The network has been created": "The network has been created.",
  "The network has been removed": "The network has been removed.",
  "The network has been updated": "The network has been updated.",
  "The network interface has been updated": "The network interface has been updated.",
  "The OBJECT_NAME has been removed": "The OBJECT_NAME has been removed.",
  "The OBJECT_NAME has been updated": "The OBJECT_NAME has been updated.",
  "The organization has been removed": "The organization has been removed.",
  "The point has been created": "The point has been created.",
  "The point has been removed": "The point has been removed.",
  "The point has been updated": "The point has been updated.",
  "The proposal for aggregator has been created": "The proposal for aggregator has been created.",
  "The proposal for aggregator has been removed": "The proposal for aggregator has been removed.",
  "The proposal has been sent to the aggregator": "The proposal has been sent to the aggregator.",
  "The proposed curtail schedule has been removed": "The proposed curtail schedule has been removed.",
  "The proposed curtail schedule has been updated": "The proposed curtail schedule has been updated.",
  "The proposed curtail schedules has been created": "The proposed curtail schedules has been created.",
  "The protocol instance has been created": "The protocol instance has been created.",
  "The protocol instance has been removed": "The protocol instance has been removed.",
  "The protocol instance has been updated": "The protocol instance has been updated.",
  "The site has been created": "The site has been created.",
  "The site has been removed": "The site has been removed",
  "The site has been updated": "The site has been updated.",
  "The space constraint detail has been created": "The space constraint detail has been created.",
  "The space constraint detail has been removed": "The space constraint detail has been removed.",
  "The space constraint detail has been updated": "The space constraint detail has been updated.",
  "The space constraint has been created": "The space constraint has been created.",
  "The space constraint has been removed": "The space constraint has been removed.",
  "The space constraint has been updated": "The space constraint has been updated.",
  "The space has been created": "The space has been created.",
  "The space has been removed": "The space has been removed.",
  "The space has been updated": "The space has been updated.",
  "The state has been created": "The state has been created.",
  "The state has been removed": "The state has been removed",
  "The state has been updated": "The state has been updated.",
  "The store has been created": "The store has been created.",
  "The store has been removed": "The store has been removed.",
  "The store has been updated": "The store has been updated.",
  "The unavailability category has been created": "The unavailability category has been created.",
  "The unavailability category has been removed": "The unavailability category has been removed.",
  "The unavailability category has been updated": "The unavailability category has been updated.",
  "The unavailability period has been created": "The unavailability period has been created.",
  "The unavailability period has been removed": "The unavailability period has been removed.",
  "The unavailability period has been updated": "The unavailability period has been updated.",
  "Timeouts": "Timeouts",
  "TLS version": "TLS version",
  "Transport type": "Transport type",
  "Type": "Type",
  "Type of bit": "Type of bit",
  "UInt": "UInt",
  "UInt{{value}}": "UInt{{value}}",
  "Unavailability categories": "Unavailability categories",
  "Unavailability categories has been removed": "Unavailability categories has been removed.",
  "Unavailability category": "Unavailability category",
  "Unavailability period": "Unavailability period",
  "Unavailability periods": "Unavailability periods",
  "Unavailability periods has been removed": "Unavailability periods has been removed.",
  "Uninhibit priority": "Uninhibit priority",
  "Unit": "Unit",
  "Unspecified loads": "Unspecified loads",
  "Upcoming load curtailment for": "Upcoming load curtailment for",
  "Update response timeouts": "Update response timeouts",
  "Updated at": "Updated at",
  "Updated by": "Updated by",
  "Updated time": "Updated time",
  "Upper bound": "Upper bound",
  "User": "User",
  "Value": "Value",
  "Vid": "Vid",
  "Weather zone": "Weather zone",
  "Writable": "Writable",
  "Write": "Write",
  "Yes": "Yes",
  "You can't delete this organization, there is sites linked to it": "You can't delete this organization, there is sites linked to it",
  "Zip code": "Zip code",
  "Zone name": "Zone name"
}

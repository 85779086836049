{
  "Alarm (4)": "Alarme (4)",
  "Analogue (2)": "Analogique (2)",
  "Analogue Input (0)": "Entrée analogique (0)",
  "Analogue Output (1)": "Sortie analogique (1)",
  "Analogue Value (2)": "Valeur analogique (2)",
  "Binary Input (3)": "Entrée binaire (3)",
  "Binary Output (4)": "Sortie binaire (4)",
  "Binary Value (5)": "Valeur binaire (5)",
  "Data type": "Type de données",
  "Digital (1)": "Numérique (1)",
  "Distributor": "Distributeur",
  "Gateway": "Passerelle",
  "Home": "Accueil",
  "Integer (3)": "Entier (3)",
  "Multi State Input (13)": "Entrée multi-états (13)",
  "Multi State Output (14)": "Sortie multi-états (14)",
  "No weather zone found": "Aucune zone météorologique trouvée.",
  "Nodes": "Nœuds",
  "Sign out": "Se déconnecter",
  "Surface area (m²)": "Surface (m²)",
  "Type": "Type"
}

import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputText } from "primereact/inputtext"

import { commonsFields, commonsOverview } from "./commons"

export const OPCUA = (nodes, loadingNodes) => {
  return {
    ...commonsFields(nodes, loadingNodes),
    ...{
      nodeid: {
        label: i18n.t("Node ID", { ns: "assets" }),
        field: InputText,
        options: {}
      },
      access_level: {
        label: i18n.t("Access level", { ns: "assets" }),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "1", label: i18n.t("Read", { ns: "assets" }) },
            { value: "2", label: i18n.t("Write", { ns: "assets" }) },
            { value: "3", label: i18n.t("Read / Write", { ns: "assets" }) }
          ]
        }
      },
      data_type: {
        label: i18n.t("Data type"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "Boolean", label: i18n.t("Boolean", { ns: "assets" }) },
            { value: "Int16", label: i18n.t("Int{{value}}", { ns: "assets", value: 16 }) },
            { value: "UInt16", label: i18n.t("UInt{{value}}", { ns: "assets", value: 16 }) },
            { value: "Int32", label: i18n.t("Int{{value}}", { ns: "assets", value: 32 }) },
            { value: "UInt32", label: i18n.t("UInt{{value}}", { ns: "assets", value: 32 }) },
            { value: "Int64", label: i18n.t("Int{{value}}", { ns: "assets", value: 64 }) },
            { value: "UInt64", label: i18n.t("UInt{{value}}", { ns: "assets", value: 64 }) },
            { value: "Float", label: i18n.t("Float", { ns: "assets" }) },
            { value: "Double", label: i18n.t("Double", { ns: "assets" }) },
            { value: "String", label: i18n.t("String", { ns: "assets" }) }
          ]
        }
      }
    }
  }
}

export const OPCUAOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      node_id: { label: i18n.t("Node ID", { ns: "assets" }) },
      access_level: { label: i18n.t("Access level", { ns: "assets" }) },
      data_type: { label: i18n.t("Data type", { ns: "assets" }) }
    }
  }
}

import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { datetimeStr } from "/utils/datetime"
import { errorNotification, successNotification } from "/utils/notification"
import { buildQueryParams } from "/utils/queryparams"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import fileDownload from "js-file-download"
import { useAuth } from "oidc-react"
import { SortOrder } from "primereact/api"
import { Button } from "primereact/button"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { exportSpaces, importSpaces, retrieveList } from "../api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [count, setCount] = useState(0)
  const [processingImport, setProcessingImport] = useState(false)
  const [processingExport, setProcessingExport] = useState(false)
  const [processingTemplate, setProcessingTemplate] = useState(false)
  const hiddenFileInput = useRef(null)

  const retrieve = async (queryParams = `?limit=${limit}&offset=${offset}`) => {
    setLoading(true)
    await retrieveList(siteStore.id, queryParams, auth.userData.access_token)
      .then(({ data }) => {
        setCount(data.count)
        data.results && setItems(data.results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoading(false))
  }

  const columns = [
    { field: "name", header: t("Name"), sortable: true },
    // { field: "surface_area", header: t("Surface area (m²)"), sortable: true },
    // { field: "height", header: t("Height (m)"), sortable: true },
    {
      field: "is_optimized",
      header: t("Optimized"),
      sortable: true,
      processValue: value => `${value ? t("Yes") : t("No")}`
    },
    {
      field: "is_permeable",
      header: "Permeable",
      sortable: true,
      processValue: value => `${value ? t("Yes") : t("No")}`
    },
    { field: "is_cooled", header: t("Cooled"), sortable: true, processValue: value => `${value ? t("Yes") : t("No")}` },
    { field: "is_heated", header: t("Heated"), sortable: true, processValue: value => `${value ? t("Yes") : t("No")}` }
  ]

  const getDetailUrl = objectId => {
    return `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/spaces/${objectId}/`
  }

  const getDeleteUrl = objectId => {
    return `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/spaces/${objectId}/delete/`
  }

  const onRowClick = objectId => {
    navigate(getDetailUrl(objectId))
  }

  const getExport = async function () {
    setProcessingExport(true)
    await exportSpaces(siteStore.id, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `spaces_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setProcessingExport(false))
  }

  const getTemplate = async function () {
    setProcessingTemplate(true)
    await exportSpaces(0, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `template_spaces_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setProcessingTemplate(false))
  }

  const postImport = async function (target) {
    const file = target.files[0]

    if (file) {
      setProcessingImport(true)
      await importSpaces(siteStore.id, file, auth.userData.access_token)
        .then(async response => {
          dispatch(addNotifications([successNotification("Import", response.data.message)]))
          setLoading(true)
          await retrieve()
          setLoading(false)
        })
        .catch(error => {
          if (error.response && error.response.headers && error.response.headers["content-type"] === "text/csv") {
            fileDownload(error.response.data, `ERROR_spaces_${datetimeStr()}.csv`)
            dispatch(addNotifications([errorNotification("Error import", t("An error occured during the import, see more details in the error report file."))]))
          } else {
            dispatch(addNotifications([errorNotification("Error import", error.message)]))
          }
        })
        .finally(() => {
          setProcessingImport(false)
          target.value = null
        })
    }
  }

  const menuEntries = [
    {
      label: t("Import"),
      icon: processingImport ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file-import",
      disabled: processingImport,
      command: () => hiddenFileInput && hiddenFileInput.current.click()
    },
    {
      label: t("Export"),
      icon: processingExport ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file-export",
      disabled: processingExport,
      command: getExport
    },
    {
      label: t("Template"),
      icon: processingTemplate ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file",
      disabled: processingTemplate,
      command: getTemplate
    }
  ]

  const menuFilters = { columns, items, filters, setFilters }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (siteStore.id && organizationStore.id) {
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/sites/` },
          { label: t("Sites"), url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          { label: t("Assets"), url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/` },
          {
            label: t("Building"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/equipments/`
          },
          {
            label: t("Spaces"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/spaces/`
          }
        ])
      )
    }
  }, [siteStore.id, organizationStore.id])

  useEffect(() => {
    retrieve(buildQueryParams(limit, offset))
  }, [limit, offset])

  return (
    <>
      <ListActions
        entries={menuEntries}
        filters={menuFilters}
      >
        <input
          className="hidden"
          ref={hiddenFileInput}
          type="file"
          onChange={e => postImport(e.target)}
        />
        <Link to={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/spaces/create/`}>
          <Button
            rounded
            severity="info"
            icon="fa-regular fa-plus"
            label={t("Create")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={items}
        objectName={t("Spaces")}
        loading={loading}
        filters={filters}
        sortOrder={SortOrder.ASC}
        sortField="name"
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
        offset={offset}
        limit={limit}
        setOffset={setOffset}
        setLimit={setLimit}
        count={count}
      />
    </>
  )
}

import axios from "axios"

function header(token) {
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

export async function retrieveList(siteId, queryParams, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/sites/${siteId}/stores/${queryParams}`, header(token))
}

export async function retrieve(siteId, storeId, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/sites/${siteId}/stores/${storeId}/`, header(token))
}

export async function add(siteId, data, token) {
  return await axios.post(`${window.BACKEND_URL}/connectivity/sites/${siteId}/stores/`, data, header(token))
}

export async function update(siteId, storeId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/connectivity/sites/${siteId}/stores/${storeId}/`, data, header(token))
}

export async function remove(siteId, storeId, token) {
  return await axios.delete(`${window.BACKEND_URL}/connectivity/sites/${siteId}/stores/${storeId}/`, header(token))
}

export async function removeStores(siteId, ids, token) {
  return await axios.post(`${window.BACKEND_URL}/connectivity/sites/${siteId}/stores/deletion/`, ids, header(token))
}

export async function getCount(storeId, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/stores/${storeId}/count`, header(token))
}

export async function retrieveConnectorInstances(siteId, gatewayId, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/sites/${siteId}/connector_instances/?gateway=${gatewayId}`, header(token))
}

export async function getAllValues(gatewayId, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/gateway/${gatewayId}/stores/allvalues`, header(token))
}

import axios from "axios"

function header(token) {
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

export async function retrieveList(queryParams, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${queryParams}`, header(token))
}

export async function retrieve(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/`, header(token))
}

export async function add(data, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/sites/`, data, header(token))
}

export async function update(siteId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/asset/sites/${siteId}/`, data, header(token))
}

export async function remove(siteId, token) {
  return await axios.delete(`${window.BACKEND_URL}/asset/sites/${siteId}/`, header(token))
}

export async function getCount(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/count`, header(token))
}

export async function getPriceZones(token) {
  return await axios.get(`${window.BACKEND_URL}/asset/price_zones/`, header(token))
}

export async function getWeatherZones(countryCode, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/weather_zones/${countryCode}/`, header(token))
}

export async function getClients(token) {
  return await axios.get(`${window.BACKEND_URL}/asset/clients/`, header(token))
}

export async function getContracts(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/operation/contracts/?site_id=${siteId}`, header(token))
}

export async function addContract(contract, token) {
  return await axios.post(`${window.BACKEND_URL}/operation/contracts/`, contract, header(token))
}

export async function updateContract(contractId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/operation/contracts/${contractId}`, data, header(token))
}

export async function getSavingShares(contractId, token) {
  return await axios.get(`${window.BACKEND_URL}/operation/savings_share?contract_id=${contractId}`, header(token))
}

export async function addSavingShare(savingShare, token) {
  return await axios.post(`${window.BACKEND_URL}/operation/savings_share`, savingShare, header(token))
}

export async function updateSavingShare(savingShareId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/operation/savings_shares/${savingShareId}`, data, header(token))
}

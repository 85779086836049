import BuildingRoutes from "/apps/assets/building/routes"
import CommunicationRoutes from "/apps/assets/communication/routes"
import ElectricalRoute from "/apps/assets/electrical/routes"
import GatewayRoutes from "/apps/connectivity/gateway/routes"
import menu from "/utils/menu"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { Cards } from "@software-engineering/hivolution-frontend-utils"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Route, Routes } from "react-router-dom"

function AssetsHome() {
  const { t } = useTranslation(["assets"])
  const siteStore = useSelector(site)
  const organizationStore = useSelector(organization)
  const dispatch = useDispatch()
  const menuEntry = menu("site", organizationStore.id, siteStore.id).filter(item => item.slug === "assets")
  const items =
    menuEntry &&
    menuEntry.pop().items.map(item => ({
      icon: item.icon,
      url: item.url,
      title: item.label
    }))

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id &&
      organizationStore.id &&
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/map/` },
          { label: t("Sites"), url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          { label: t("Assets"), url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/` }
        ])
      )
  }, [siteStore.id, organizationStore.id])

  return <>{items && !!items.length && <Cards items={items} />}</>
}

export default function AssetRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<AssetsHome />}
      />
      <Route
        path="communication/*"
        element={<CommunicationRoutes />}
      />
      <Route
        path="building/*"
        element={<BuildingRoutes />}
      />
      <Route
        path="gateways/*"
        element={<GatewayRoutes />}
      />
      <Route
        path="electrical/*"
        element={<ElectricalRoute />}
      />
    </Routes>
  )
}

import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const Store = (connectorInstances, loadingConnectorInstances) => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: {
      label: i18n.t("Name", { ns: "connectivity" }),
      field: InputText,
      options: {
        required: true,
        keyfilter: /[\w\d_]/
      }
    },
    description: { label: i18n.t("Description", { ns: "connectivity" }), field: InputTextarea, options: {} },
    pulling_interval: {
      label: i18n.t("Pulling interval", { ns: "connectivity" }),
      field: InputNumber,
      options: { required: true, showButtons: true }
    },
    type: {
      label: i18n.t("Type", { ns: "connectivity" }),
      field: Dropdown,
      options: {
        required: true,
        options: [
          { label: i18n.t("Status", { ns: "connectivity" }), value: "status" },
          { label: i18n.t("Gauge", { ns: "connectivity" }), value: "gauge" }
        ],
        placeholder: "Select type"
      }
    },
    connector_instance: {
      label: i18n.t("Connector instance", { ns: "connectivity" }),
      field: Dropdown,
      options: {
        required: true,
        options: connectorInstances,
        optionLabel: "name",
        optionValue: "id",
        loading: loadingConnectorInstances,
        placeholder: loadingConnectorInstances ? "Loading..." : "Select connector instance"
      }
    },
    resolution_interval: {
      label: i18n.t("Resolution interval", { ns: "connectivity" }),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "gauge",
      options: {
        required: true,
        showButtons: true
      }
    }
  }
}

export const StoreStatusOverview = () => {
  return {
    name: { label: i18n.t("Name", { ns: "connectivity" }) },
    description: { label: i18n.t("Description", { ns: "connectivity" }) },
    pulling_interval: { label: i18n.t("Pulling interval", { ns: "connectivity" }) },
    type: { label: i18n.t("Type", { ns: "connectivity" }) },
    connector_instance_name: { label: i18n.t("Connector instance", { ns: "connectivity" }) }
  }
}

export const StoreGaugeOverview = () => {
  return {
    name: { label: i18n.t("Name", { ns: "connectivity" }) },
    description: { label: i18n.t("Description", { ns: "connectivity" }) },
    pulling_interval: { label: i18n.t("Pulling interval", { ns: "connectivity" }) },
    type: { label: i18n.t("Type", { ns: "connectivity" }) },
    connector_instance_name: { label: i18n.t("Connector instance", { ns: "connectivity" }) },
    resolution_interval: { label: i18n.t("Resolution interval", { ns: "connectivity" }) }
  }
}

import { retrieveList } from "/apps/connectivity/connector-instance/api"
import { GatewayRoutesContext } from "/apps/connectivity/gateway/routes"
import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { errorNotification } from "/utils/notification"
import { buildQueryParams } from "/utils/queryparams"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const [selected, setSelected] = useState([])
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [count, setCount] = useState(0)
  const { gateway } = useContext(GatewayRoutesContext)
  const DEFAULT_FILTER = { gateway: [gateway.id] }

  const columns = [
    { field: "name", header: t("Name"), sortable: true },
    // { field: "protocols", header: t("Protocols") },
    // { field: "description", header: t("Description") },
    // { field: "stores_response_timeout", header: "Response timeout" },
    { field: "number_stores", header: t("Number of stores") },
    { field: "number_points", header: t("Number of points") }
  ]

  const retrieve = async queryParams => {
    setLoading(true)
    await retrieveList(siteStore.id, queryParams, auth.userData.access_token)
      .then(({ data }) => {
        setCount(data.count)
        data.results && setItems(data.results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Connector instances", error.message)]))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getDetailUrl = connectorId => `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connectorId}/`

  const getDeleteUrl = connectorId => `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connectorId}/delete/`

  const onRowClick = connectorId => {
    navigate(getDetailUrl(connectorId))
  }

  const menuFilters = { items, columns, filters, setFilters }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id &&
      gateway &&
      gateway.id &&
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/` },
          { label: t("Sites"), url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          { label: t("Assets"), url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/` },
          {
            label: t("Gateways"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/`
          },
          {
            label: gateway.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/`
          },
          {
            label: t("Connector instances"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/`
          }
        ])
      )
  }, [siteStore.id, gateway])

  useEffect(() => {
    retrieve(buildQueryParams(limit, offset, DEFAULT_FILTER))
  }, [limit, offset])

  return (
    <>
      {siteStore.id && (
        <section className="list-section">
          <ListActions filters={menuFilters}>
            <Link to={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/create/`}>
              <Button
                rounded
                severity="info"
                icon="fa-regular fa-plus"
                label={t("Create")}
              />
            </Link>
          </ListActions>

          <ListLayout
            columns={columns}
            value={items}
            objectName={t("Connector instances")}
            loading={loading}
            filters={filters}
            getDetailUrl={getDetailUrl}
            getDeleteUrl={getDeleteUrl}
            onRowClick={onRowClick}
            bulkDeleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/multi-delete/?${selected.map(item => `id=${item.id}`).join("&")}`}
            handleSelection={setSelected}
            limit={limit}
            offset={offset}
            setLimit={setLimit}
            setOffset={setOffset}
            count={count}
          />
        </section>
      )}
    </>
  )
}

import { SelectModal } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { commonsFields, commonsOverview } from "./commons"

export const SMMeterNode = (spaces, smNodes) => {
  return {
    ...commonsFields(spaces),
    ...{
      sm_node: {
        label: i18n.t("System manager", { ns: "assets" }),
        field: SelectModal,
        options: {
          items: smNodes,
          columns: [
            { field: "name", header: i18n.t("Name", { ns: "assets" }) },
            { field: "model", header: i18n.t("Model", { ns: "assets" }) },
            { field: "manufacturer", header: i18n.t("Manufacturer", { ns: "assets" }) },
            { field: "proto", header: i18n.t("Protocol", { ns: "assets" }) },
            { field: "sm_type", header: i18n.t("SM Type", { ns: "assets" }) },
            { field: "software", header: i18n.t("Software", { ns: "assets" }) },
            { field: "user", header: i18n.t("User", { ns: "assets" }) }
          ],
          required: true,
          objectName: i18n.t("SM Nodes", { ns: "assets" }),
          searchLabel: id => {
            let find = smNodes.find(node => node.id === id)
            return find ? find.name : ""
          }
        }
      },
      node_addr: {
        label: i18n.t("Node address", { ns: "assets" }),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true
        }
      },
      meter_id: {
        label: i18n.t("Meter ID", { ns: "assets" }),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true
        }
      }
    }
  }
}

export const SMMeterOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      sm_node_label: { label: i18n.t("System manager", { ns: "assets" }) },
      node_addr: { label: i18n.t("Node address", { ns: "assets" }) },
      meter_id: { label: i18n.t("Meter ID", { ns: "assets" }) }
    }
  }
}

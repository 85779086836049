import { errorNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { resetOrganization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { resetSite } from "/utils/store/site"
import { BaseLayout, CopyButton, ListActions, ListLayout, Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import { TabPanel, TabView } from "primereact/tabview"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { retrieveList } from "../api"

const CardsLayout = props => {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a list of cards.
   * It is placed in a TabList.
   *
   * @component
   * @example
   *
   * export default function List () {
   *   const [activeIndex, setActiveIndex] = useState(0)
   *   const items = [{label: "Cards"}, ...]
   *   return (
   *     <>
   *       <section>
   *         <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
   *         {activeIndex === 0 && <CardsLayout />}
   *         ...
   *       </section>
   *     </>
   *   )
   * }
   *
   *
   */
  const { t } = useTranslation(["organization"])

  return (
    <div className="organizations-cards">
      {props.items &&
        props.items.map(organization => (
          <Link
            to={`/organizations/${organization.id}/map/`}
            key={organization.id}
          >
            <div className="organization-card">
              {organization.logo ? (
                <div
                  className="organization-card-logo"
                  dangerouslySetInnerHTML={{ __html: organization.logo }}
                />
              ) : (
                <div className="organization-card-logo">
                  <i className="fa-solid fa-image" />
                </div>
              )}

              <p className="organization-card-title">{organization.name}</p>
              <div className="organization-card-infos">
                <p>{t("{{count}} sites_count", { count: organization.sites_count })}</p>
              </div>
            </div>
          </Link>
        ))}
    </div>
  )
}

const TableLayout = props => {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component.
   * It is placed in a TabList.
   *
   * @component
   * @example
   *
   * export default function List () {
   *   const [activeIndex, setActiveIndex] = useState(0)
   *   const items = [{label: "Table"}, ...]
   *   return (
   *     <>
   *       <section>
   *         <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
   *         {activeIndex === 0 && <TableLayout />}
   *         ...
   *       </section>
   *     </>
   *   )
   * }
   *
   *
   */

  const actionsBodyTemplate = object => {
    return (
      <div className="p-datatable-action-buttons">
        <CopyButton
          isUrl
          icon="fa-solid fa-link"
          value={`/organizations/${object.id}/map/`}
        />

        <Link
          className="update-button"
          to={`/organizations/${object.id}/`}
        >
          <i className="fa-solid fa-pen" />
        </Link>

        <Link
          className="delete-button"
          to={`/organizations/${object.id}/delete/`}
        >
          <i className="fa-regular fa-trash-can" />
        </Link>
      </div>
    )
  }

  const navigate = useNavigate()
  const { t } = useTranslation(["organization"])

  const columns = [
    { field: "name", header: t("Name") },
    { field: "sites_count", header: t("Number of sites") }
  ]

  const onRowClick = objectId => {
    navigate(`/organizations/${objectId}/map/`)
  }

  return (
    <>
      {props.items && (
        <ListLayout
          columns={columns}
          value={props.items}
          objectName={t("Organizations")}
          onRowClick={onRowClick}
          actionsBodyTemplate={actionsBodyTemplate}
        />
      )}
    </>
  )
}

export default function TabList() {
  /*
   * This component work with PrimeReact
   * It display a TabMenu component with one or more <componentLayout>.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<TabList />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const { t } = useTranslation(["organization"])
  const dispatch = useDispatch()
  const auth = useAuth()
  const [organizations, setOrganizations] = useState([])
  const [loading, setLoading] = useState(false)

  const retrieve = async () => {
    setLoading(true)
    await retrieveList(auth.userData.access_token)
      .then(response => {
        response.data && response.data.results && setOrganizations(response.data.results)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    dispatch(resetSite())
    dispatch(resetOrganization())
    dispatch(updateSideBar({ menuName: null, siteId: null }))
    dispatch(updateBreadcrumb())
    retrieve()
  }, [])

  return (
    <BaseLayout title={t("Organizations")}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <ListActions>
            <Link to={"/organizations/create/"}>
              <Button
                rounded
                severity="info"
                icon="fa-regular fa-plus"
                label={t("Create")}
              />
            </Link>
          </ListActions>
          <TabView>
            <TabPanel header={t("Cards")}>
              <CardsLayout items={organizations} />
            </TabPanel>
            <TabPanel header={t("List")}>
              <TableLayout items={organizations} />
            </TabPanel>
          </TabView>
        </>
      )}
    </BaseLayout>
  )
}

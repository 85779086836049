import { Dropdown, SelectModal } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"
import { commonsFields, commonsOverview } from "./commons"

export const SMIONode = (spaces, smNodes) => {
  return {
    ...commonsFields(spaces),
    ...{
      sm_node: {
        label: i18n.t("System manager", { ns: "assets" }),
        field: SelectModal,
        options: {
          items: smNodes,
          columns: [
            { field: "name", header: i18n.t("Name", { ns: "assets" }) },
            { field: "model", header: i18n.t("Model", { ns: "assets" }) },
            { field: "manufacturer", header: i18n.t("Manufacturer", { ns: "assets" }) },
            { field: "proto", header: i18n.t("Protocol", { ns: "assets" }) },
            { field: "sm_type", header: i18n.t("SM Type", { ns: "assets" }) },
            { field: "software", header: i18n.t("Software", { ns: "assets" }) },
            { field: "user", header: i18n.t("User", { ns: "assets" }) }
          ],
          required: true,
          objectName: i18n.t("SM Nodes", { ns: "assets" }),
          searchLabel: id => {
            let find = smNodes.find(node => node.id === id)
            return find ? find.name : ""
          }
        }
      },
      node_addr: {
        label: i18n.t("Node address", { ns: "assets" }),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true
        }
      },
      node_type: {
        label: i18n.t("IO node type", { ns: "assets" }),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: 0, label: i18n.t("0: digital input", { ns: "assets" }) },
            { value: 1, label: i18n.t("1: digital output", { ns: "assets" }) },
            { value: 2, label: i18n.t("2: analog input", { ns: "assets" }) },
            { value: 3, label: i18n.t("3: analog output", { ns: "assets" }) }
          ]
        }
      },
      device_type: {
        label: i18n.t("Device type", { ns: "assets" }),
        field: InputText,
        options: {}
      }
    }
  }
}

export const SMIOOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      sm_node_label: { label: i18n.t("System manager", { ns: "assets" }) },
      node_addr: { label: i18n.t("Node address", { ns: "assets" }) },
      node_type: { label: i18n.t("IO node type", { ns: "assets" }) },
      device_type: { label: i18n.t("Device type", { ns: "assets" }) }
    }
  }
}

import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputText } from "primereact/inputtext"

export const NetworkInterfaceOverview = () => {
  return {
    // context_name: { label: i18n.t("Name", {ns: "connectivity" }) },
    // label: { label: i18n.t("Label", {ns: "connectivity" }) },
    gateway_name: { label: i18n.t("Gateway", { ns: "connectivity" }) },
    network_label: { label: i18n.t("Network", { ns: "connectivity" }) },
    ip_addr: { label: i18n.t("IP Address", { ns: "connectivity" }) }
  }
}

export const NetworkInterface = () => {
  return {
    gateway_name: {
      label: i18n.t("Gateway", { ns: "connectivity" }),
      field: InputText,
      options: {
        disabled: true
      }
    },
    network_label: {
      label: i18n.t("Network", { ns: "connectivity" }),
      field: InputText,
      options: {
        disabled: true
      }
    },
    ip_addr: {
      label: i18n.t("IP Address", { ns: "connectivity" }),
      field: InputText,
      options: { required: true }
    }
  }
}

export const NetworkIPInterface = (networks, loadingNetworks) => {
  return {
    network: {
      label: i18n.t("Network", { ns: "connectivity" }),
      field: Dropdown,
      options: {
        required: true,
        options: networks,
        optionLabel: "label",
        optionValue: "id",
        loading: loadingNetworks,
        placeholder: loadingNetworks ? "Loading..." : "Select network"
      }
    },
    ip_addr: {
      label: i18n.t("IP Address", { ns: "connectivity" }),
      field: InputText,
      options: { required: true }
    }
  }
}

import axios from "axios"

function header(token) {
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

export async function retrieveList(siteId, queryParams, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/sites/${siteId}/connector_instances/${queryParams}`, header(token))
}

export async function retrieve(siteId, connectorInstanceId, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/sites/${siteId}/connector_instances/${connectorInstanceId}/`, header(token))
}

export async function add(siteId, data, token) {
  return await axios.post(`${window.BACKEND_URL}/connectivity/sites/${siteId}/connector_instances/`, data, header(token))
}

export async function update(siteId, connectorInstanceId, data, token) {
  return await axios.put(`${window.BACKEND_URL}/connectivity/sites/${siteId}/connector_instances/${connectorInstanceId}/`, data, header(token))
}

export async function remove(siteId, connectorInstanceId, token) {
  return await axios.delete(`${window.BACKEND_URL}/connectivity/sites/${siteId}/connector_instances/${connectorInstanceId}/`, header(token))
}

export async function getCount(connectorInstanceId, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/connector_instances/${connectorInstanceId}/count/`, header(token))
}

export async function getConnectorInstancesCount(ids, token) {
  return await axios.post(`${window.BACKEND_URL}/connectivity/connector_instances/count/`, ids, header(token))
}

export async function removeConnectorInstances(siteId, ids, token) {
  return await axios.post(`${window.BACKEND_URL}/connectivity/sites/${siteId}/connector_instances/deletion/`, ids, header(token))
}

import axios from "axios"

function header(token) {
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

function headerFile(token) {
  return {
    headers: {
      "Content-Type": "multipart/form-data; charset=UTF-8",
      "Authorization": `Bearer ${token}`
    }
  }
}

export async function retrieveList(siteId, queryParams, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/points/${queryParams}`, header(token))
}

export async function retrieve(siteId, id, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/points/${id}/`, header(token))
}

export async function add(siteId, data, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/points/`, data, header(token))
}

export async function update(siteId, id, data, token) {
  return await axios.put(`${window.BACKEND_URL}/asset/sites/${siteId}/points/${id}/`, data, header(token))
}

export async function remove(siteId, id, token) {
  return await axios.delete(`${window.BACKEND_URL}/asset/sites/${siteId}/points/${id}/`, header(token))
}

export async function getEquipments(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/equipments/`, header(token))
}

export async function getParameters(token) {
  return await axios.get(`${window.BACKEND_URL}/asset/parameters/`, header(token))
}

export async function getStores(siteId, storeType, token) {
  return await axios.get(`${window.BACKEND_URL}/connectivity/sites/${siteId}/stores/?type=${storeType}`, header(token))
}

export async function removePoints(siteId, ids, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/points/deletion/`, ids, header(token))
}

export async function getPointsCount(ids, token) {
  return await axios.post(`${window.BACKEND_URL}/asset/points/count/`, ids, header(token))
}

export async function getAllValues(siteId, token) {
  return await axios.get(`${window.BACKEND_URL}/asset/sites/${siteId}/points/allvalues`, header(token))
}

export async function exportPoints(siteId, token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/asset/sites/${siteId}/points/bulk/export`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function importPoints(siteId, file, token) {
  let formData = new FormData()
  formData.append("file", file)
  return await axios.post(`${window.BACKEND_URL}/asset/sites/${siteId}/points/bulk/import`, formData, headerFile(token), { signal: new AbortController() })
}

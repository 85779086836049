import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { datetimeStr } from "/utils/datetime"
import { errorNotification, successNotification } from "/utils/notification"
import { buildQueryParams } from "/utils/queryparams"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import fileDownload from "js-file-download"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { exportPoints, getAllValues, importPoints, retrieveList } from "../api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const [searchParams] = useSearchParams()
  const selectedGateway = searchParams.get("gateway_name")
  const { t } = useTranslation(["assets"])
  const hiddenFileInput = useRef(null)
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingFilters, setLoadingFilters] = useState(false)
  const [filters, setFilters] = useState(selectedGateway ? { gateway_name: [selectedGateway] } : {})
  const [filterValues, setFilterValues] = useState(null)
  const [selected, setSelected] = useState([])
  const [selectedFilters, setSelectedFilters] = useState([])
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [count, setCount] = useState(0)
  const [processingImport, setProcessingImport] = useState(false)
  const [processingExport, setProcessingExport] = useState(false)
  const [processingTemplate, setProcessingTemplate] = useState(false)
  const [sortOrder, setSortOrder] = useState(null)
  const [sortField, setSortField] = useState(null)

  const retrieve = async (queryParams = `?limit=${limit}&offset=${offset}`) => {
    setLoading(true)
    await retrieveList(siteStore.id, queryParams, auth.userData.access_token)
      .then(({ data }) => {
        setCount(data.count)
        data.results && setItems(data.results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoading(false))
  }

  const getFilterValues = async () => {
    setLoadingFilters(true)
    await getAllValues(siteStore.id, auth.userData.access_token)
      .then(response => {
        setFilterValues(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setLoadingFilters(false))
  }

  const onSort = sortEvent => {
    setSortOrder(sortEvent.sortOrder)
    setSortField(sortEvent.sortField)
  }

  const columns = [
    { field: "name", header: t("Name"), sortable: true },
    { field: "equipment_name", header: t("Equipment"), sortable: true },
    { field: "automation_point", header: t("Automation point"), sortable: true },
    // { field: "automation_point_type", header: t("Automation point type"), sortable: true },
    { field: "node", header: t("Node"), sortable: true },
    { field: "gateway_name", header: t("Gateway"), sortable: true },
    { field: "read_write", header: t("Read / Write"), sortable: true },
    { field: "external_id", header: t("External ID"), sortable: true }
  ]

  const columnsFilter = [
    { field: "name", header: t("Name") },
    { field: "external_id", header: t("External ID") },
    { field: "node", header: t("Node") },
    { field: "gateway_name", header: t("Gateway") },
    { field: "equipment_name", header: t("Equipment") },
    { field: "automation_point", header: t("Automation point") },
    // { field: "automation_point_type", header: t("Automation point type") },
    { field: "read", header: t("Read") },
    { field: "write", header: t("Write") }
    // { field: "info", header: t("Info") }
  ]

  const getDetailUrl = objectId => `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/points/${objectId}/`

  const getDeleteUrl = objectId => `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/points/${objectId}/delete/`

  const onRowClick = objectId => {
    navigate(getDetailUrl(objectId))
  }

  const getExport = async () => {
    setProcessingExport(true)
    await exportPoints(siteStore.id, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `points_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setProcessingExport(false))
  }

  const getTemplate = async () => {
    setProcessingTemplate(true)
    await exportPoints(0, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `template_point_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => setProcessingTemplate(false))
  }

  const postImport = async target => {
    const file = target.files[0]
    if (file) {
      setProcessingImport(true)
      await importPoints(siteStore.id, file, auth.userData.access_token)
        .then(response => {
          dispatch(addNotifications([successNotification("Import", response.data)]))
          retrieve()
        })
        .catch(error => {
          if (error.response && error.response.headers && error.response.headers["content-type"] === "text/csv") {
            fileDownload(error.response.data, `ERROR_points_${datetimeStr()}.csv`)
            dispatch(addNotifications([errorNotification("Error import", t("An error occured during the import, see more details in the error report file."))]))
          } else {
            dispatch(addNotifications([errorNotification("Error import", error.message)]))
          }
        })
        .finally(() => setProcessingImport(false))
    }
  }

  const menuEntries = [
    {
      label: t("Import"),
      icon: processingImport ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file-import",
      disabled: processingImport,
      command: () => hiddenFileInput && hiddenFileInput.current.click()
    },
    {
      label: t("Export"),
      icon: processingExport ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file-export",
      disabled: processingExport,
      command: getExport
    },
    {
      label: t("Template"),
      icon: processingTemplate ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file",
      disabled: processingTemplate,
      command: getTemplate
    }
  ]

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [])

  useEffect(() => {
    if (siteStore.id && organizationStore.id) {
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/map/` },
          { label: t("Sites"), url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          { label: t("Assets"), url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/` },
          {
            label: t("Building"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/equipments/`
          },
          {
            label: t("Points"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/points/`
          }
        ])
      )
      getFilterValues()
    }
  }, [siteStore.id, organizationStore.id])

  useEffect(() => {
    setSelectedFilters(selectedGateway ? ["gateway_name"] : [])
    setFilters(selectedGateway ? { ...filters, gateway_name: [selectedGateway] } : {})
  }, [selectedGateway])

  useEffect(() => {
    siteStore.id && (offset ? setOffset(REQUEST_DEFAULT_OFFSET) : retrieve(buildQueryParams(limit, offset, filters, sortField, sortOrder)))
  }, [filters, sortField, sortOrder])

  useEffect(() => {
    siteStore.id && retrieve(buildQueryParams(limit, offset, filters, sortField, sortOrder))
  }, [limit, offset])

  const menuFilters = { columnsFilter, filters, setFilters, filterValues }

  return (
    <>
      {siteStore.id && (
        <>
          <ListActions
            entries={menuEntries}
            filterBackend={true}
            filters={menuFilters}
            loading={loadingFilters}
            selectedFilters={selectedFilters}
          >
            <input
              className="hidden"
              ref={hiddenFileInput}
              type="file"
              onChange={e => postImport(e.target)}
            />
            <Link to={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/points/create/`}>
              <Button
                rounded
                severity="info"
                icon="fa-regular fa-plus"
                label={t("Create")}
              />
            </Link>
          </ListActions>
          <ListLayout
            columns={columns}
            value={items}
            objectName={t("Points")}
            loading={loading}
            getDetailUrl={getDetailUrl}
            getDeleteUrl={getDeleteUrl}
            onRowClick={onRowClick}
            handleSelection={setSelected}
            bulkDeleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/points/multi-delete/?${selected.map(item => `id=${item.id}`).join("&")}`}
            offset={offset}
            limit={limit}
            setOffset={setOffset}
            setLimit={setLimit}
            count={count}
            onSort={onSort}
            sortOrder={sortOrder}
            sortField={sortField}
          />
        </>
      )}
    </>
  )
}

import i18n from "i18next"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"

export const Organization = () => {
  return {
    name: { label: i18n.t("Name", { ns: "organization" }), field: InputText, options: { required: true } },
    address: { label: i18n.t("Address", { ns: "organization" }), field: InputText, options: { required: true } },
    active: {
      label: i18n.t("Active", { ns: "organization" }),
      field: InputSwitch
    }
  }
}

import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { errorNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { BaseLayout, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { retrieveList } from "../api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const [parameters, setParameters] = useState([])
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["configuration"])
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [count, setCount] = useState(0)

  const retrieve = async (queryParams = `?limit=${limit}&offset=${offset}`) => {
    // TODO: uncomment when the API will no longer paginate
    await retrieveList(queryParams, auth.userData.access_token)
      .then(({ data }) => {
        data.results && setParameters(data.results)
        setCount(data.count)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    dispatch(updateBreadcrumb([{ label: t("Parameters"), url: "/connectivity/parameters/" }]))
  }, [])

  useEffect(() => {
    retrieve()
  }, [limit, offset])

  const columns = [
    { field: "name", header: t("Name"), sortable: true },
    { field: "unit", header: t("Unit"), sortable: true },
    { field: "quantity", header: t("Quantity"), sortable: true }
  ]

  return (
    <BaseLayout title={t("Parameters")}>
      <ListLayout
        objectName={t("Parameters")}
        columns={columns}
        value={parameters}
        loading={loading}
        limit={limit}
        offset={offset}
        setLimit={setLimit}
        setOffset={setOffset}
        count={count}
      />
    </BaseLayout>
  )
}

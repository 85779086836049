import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getUnavailabilityCategories, update } from "../api"
import { UnavailabilityPeriod, UnavailabilityPeriodOverview } from "../models"
import { UnavailabilityPeriodRouteContext } from "../routes"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const { unavailabilityPeriod, retrieveDetail } = useContext(UnavailabilityPeriodRouteContext)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingUnavailabilityCategories, setLoadingUnavailabilityCategories] = useState(false)
  const [unavailabilityCategories, setUnavailabilityCategories] = useState([])

  const retrieveCategories = async () => {
    setLoadingUnavailabilityCategories(true)
    getUnavailabilityCategories(siteStore.id, auth.userData.access_token)
      .then(({ data }) => {
        data.results && setUnavailabilityCategories(data.results)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setLoadingUnavailabilityCategories(false))
  }

  const updateObject = async function (e) {
    e.preventDefault()
    setProcessing(true)
    await update(
      siteStore.id,
      unavailabilityPeriod.id,
      {
        ...data,
        updated_by: auth.userData.profile.preferred_username
      },
      auth.userData.access_token
    )
      .then(() => {
        dispatch(addNotifications([successNotification("Update", t("The unavailability period has been updated."))]))
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (unavailabilityPeriod) {
      setLoading(false)
      setData(unavailabilityPeriod)
    }
  }, [unavailabilityPeriod])

  useEffect(() => {
    edit && retrieveCategories()
  }, [edit])

  useEffect(() => {
    if (siteStore.id) {
      unavailabilityPeriod &&
        unavailabilityPeriod.id &&
        dispatch(
          updateBreadcrumb([
            { label: organizationStore.name, url: `/organizations/${organizationStore.id}/` },
            { label: t("Sites"), url: `/organizations/${organizationStore.id}/sites/` },
            {
              label: siteStore.pretty_name ?? siteStore.name,
              url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
            },
            {
              label: t("Unavailability periods"),
              url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/unavailability-periods/`
            },
            ,
            {
              label: t("Detail"),
              url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/unavailability-periods/${unavailabilityPeriod.id}/`
            }
          ])
        )
    }
  }, [siteStore.id, unavailabilityPeriod])

  return (
    <>
      {unavailabilityPeriod && (
        <>
          <div className="base-content-title">
            <h2>
              {unavailabilityPeriod.start_date} {unavailabilityPeriod.start_time}
              {unavailabilityPeriod.end_date && (
                <span className="arrow">
                  <i className="fa-solid fa-arrow-right" />
                </span>
              )}
              {unavailabilityPeriod.end_date} {unavailabilityPeriod.end_time}
            </h2>
          </div>
          <OverView
            object={unavailabilityPeriod}
            model={UnavailabilityPeriod(unavailabilityCategories, loadingUnavailabilityCategories)}
            overviewModel={UnavailabilityPeriodOverview()}
            updateObject={updateObject}
            data={data}
            setData={setData}
            errors={errors}
            edit={edit}
            loading={loading}
            processing={processing}
            setEdit={setEdit}
            deleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/unavailability-periods/${unavailabilityPeriod.id}/delete/`}
          />
        </>
      )}
    </>
  )
}

import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { errorNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { retrieveList } from "../api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["configuration"])
  const [gatewayBoxes, setGatewayBoxes] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [count, setCount] = useState(0)

  const retrieve = async (queryParams = `?limit=${limit}&offset=${offset}`) => {
    await retrieveList(queryParams, auth.userData.access_token)
      .then(({ data }) => {
        data.results && setGatewayBoxes(data.results)
        setCount(data.count)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    dispatch(
      updateBreadcrumb([
        { label: t("Gateways"), url: "/configuration/gateways/boxes/" },
        { label: t("Boxes"), url: "/configuration/gateways/boxes/" }
      ])
    )
  }, [])

  useEffect(() => {
    retrieve()
  }, [limit, offset])

  const columns = [
    { field: "serial_id", header: t("Serial ID"), sortable: true },
    { field: "manufacturer", header: t("Manufacturer"), sortable: true },
    { field: "model", header: t("Model"), sortable: true }
  ]

  const getDetailUrl = id => {
    return `/configuration/gateways/boxes/${id}/`
  }

  const getDeleteUrl = id => {
    return `/configuration/gateways/boxes/${id}/delete/`
  }

  const onRowClick = id => {
    navigate(getDetailUrl(id))
  }

  const headerTemplate = ({ manufacturer }) => manufacturer || "No manufacturer"
  const menuFilters = { columns, items: gatewayBoxes, filters, setFilters }

  return (
    <>
      <ListActions filters={menuFilters}>
        <Link to="/configuration/gateways/boxes/create/">
          <Button
            rounded
            icon="fa-regular fa-plus"
            label={t("Create")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={gatewayBoxes}
        filters={filters}
        objectName={t("Gateway boxes")}
        loading={loading}
        rowGroupHeaderTemplate={headerTemplate}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
        limit={limit}
        offset={offset}
        setLimit={setLimit}
        setOffset={setOffset}
        count={count}
      />
    </>
  )
}

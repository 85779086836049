import { retrieveList } from "/apps/assets/electrical/circuit-breaker/api"
import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { errorNotification } from "/utils/notification"
import { buildQueryParams } from "/utils/queryparams"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site.js"
import { ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { SortOrder } from "primereact/api"
import { Button } from "primereact/button"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [items, setItems] = useState([])
  const [filters, setFilters] = useState({})
  const [count, setCount] = useState(0)
  const [limit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset] = useState(REQUEST_DEFAULT_OFFSET)
  const [loading, setLoading] = useState(true)
  const hiddenFileInput = useRef(null)
  const [sortOrder] = useState(SortOrder.ASC)
  const [sortField] = useState("name")

  const columns = [
    { field: "name", header: t("Name"), sortable: true },
    { field: "cabinet", header: t("Cabinet"), sortable: true },
    { field: "nominal_intesity", header: t("Nominal intensity"), sortable: true },
    { field: "equipments_powered_count", header: t("Equipments powered"), sortable: true }
  ]

  const getDetailUrl = objectId => `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/electrical/circuit-breakers/${objectId}/`

  const getDeleteUrl = objectId => `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/electrical/circuit-breakers/${objectId}/delete/`

  const onRowClick = objectId => navigate(getDetailUrl(objectId))

  const getExport = async () => {
    await export_OBJECT_NAME(siteId, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `OBJECT_NAME_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const getTemplate = async () => {
    await exportTemplate(siteId, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `template_OBJECT_NAME_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
  }

  const postImport = async target => {
    const file = target.files[0]
    if (file) {
      await import_OBJECT_NAME(siteId, file, auth.userData.access_token)
        .then(response => {
          dispatch(addNotifications([successNotification("Import", response.data)]))
          retrieve()
        })
        .catch(error => {
          if (error.response && error.response.headers && error.response.headers["content-type"] === "text/csv") {
            fileDownload(error.response.data, `ERROR_OBJECT_NAME_${datetimeStr()}.csv`)
            dispatch(addNotifications([errorNotification("Error import", t("An error occured during the import, see more details in the error report file."))]))
          } else {
            dispatch(addNotifications([errorNotification("Error import", error.message)]))
          }
        })
    }
  }

  const retrieve = async (queryParams = `?limit=${limit}&offset=${offset}`) => {
    setLoading(true)
    await retrieveList(siteStore.id, queryParams, auth.userData.access_token)
      .then(({ data }) => {
        if (data) {
          setCount(data.length)
          setItems(data)
        }
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Error", message)]))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const menuEntries = [
    {
      label: t("Import"),
      icon: "fa-solid fa-file-import",
      command: () => hiddenFileInput && hiddenFileInput.current.click()
    },
    {
      label: t("Export"),
      icon: "fa-solid fa-file-export",
      command: getExport
    },
    {
      label: t("Template"),
      icon: "fa-solid fa-file",
      command: getTemplate
    }
  ]

  const menuFilters = { columns, items, filters, setFilters }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (siteStore.id && organizationStore.id) {
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/` },
          { label: t("Sites"), url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          { label: t("Assets"), url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/` },
          {
            label: t("Electrical"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/electrical/circuit-breakers/`
          },
          {
            label: t("Circuit breakers"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/electrical/circuit-breakers/`
          }
        ])
      )

      retrieve(buildQueryParams(limit, offset, filters, sortField, sortOrder))
    }
  }, [siteStore.id, organizationStore.id])

  return (
    <>
      <ListActions
        entries={menuEntries}
        filters={menuFilters}
      >
        <input
          className="hidden"
          ref={hiddenFileInput}
          type="file"
          onChange={postImport}
        />

        <Link to={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/electrical/circuit-breakers/create/`}>
          <Button
            rounded
            severity="info"
            icon="fa-regular fa-plus"
            label={t("Create")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={items}
        sortField="name"
        sortOrder={SortOrder.ASC}
        objectName={t("Circuit breakers")}
        loading={loading}
        filters={filters}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
        count={count}
      />
    </>
  )
}

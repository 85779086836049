import { retrieveList } from "/apps/configuration/edge-block-template/api"
import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { errorNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { BaseLayout, ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation(["configuration"])

  const [edgeBlockTemplates, setEdgeBlockTemplates] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [count, setCount] = useState(0)

  console.log("Edge block template")

  const retrieve = async (queryParams = `?limit=${limit}&offset=${offset}`) => {
    await retrieveList(queryParams, auth.userData.access_token)
      .then(({ data }) => {
        data.results && setEdgeBlockTemplates(data.results)
        setCount(data.count)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification("Error", error.message)]))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    dispatch(updateBreadcrumb([{ label: t("Edge block templates"), url: "/configuration/edge-block-templates/" }]))
    retrieve()
  }, [])

  useEffect(() => {
    retrieve()
  }, [limit, offset])

  const columns = [
    { field: "name", header: t("Name"), sortable: true },
    { field: "priority", header: t("Priority"), sortable: true },
    { field: "updated_by", header: t("Updated by"), sortable: true },
    { field: "description", header: t("Description") }
  ]

  const getDetailUrl = id => {
    return `/configuration/edge-block-templates/${id}/`
  }

  const getDeleteUrl = id => {
    return `/configuration/edge-block-templates/${id}/delete/`
  }

  const onRowClick = id => {
    navigate(getDetailUrl(id))
  }

  const menuFilters = { columns, items: edgeBlockTemplates, filters, setFilters }

  return (
    <BaseLayout title={t("Edge block templates")}>
      <ListActions filters={menuFilters}>
        <Link to="/configuration/edge-block-templates/create/">
          <Button
            rounded
            className="button"
            icon="fa-regular fa-plus"
            label={t("Create edge block template")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={edgeBlockTemplates}
        objectName={t("Edge block templates")}
        loading={loading}
        filters={filters}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
        limit={limit}
        offset={offset}
        setLimit={setLimit}
        setOffset={setOffset}
        count={count}
      />
    </BaseLayout>
  )
}

import { GatewayRoutesContext } from "/apps/connectivity/gateway/routes"
import { StoreRoutesContext } from "/apps/connectivity/store/routes"
import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { updateBreadcrumb } from "/utils/store/breadcrumb"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { update } from "../api"
import { Store, StoreGaugeOverview, StoreStatusOverview } from "../models"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation(["assets"])
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [connectorInstances, setConnectorInstances] = useState([])
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingConnectorInstances, setLoadingConnectorInstances] = useState(false)
  const [processing, setProcessing] = useState(false)
  const { store, retrieveDetail } = useContext(StoreRoutesContext)
  const { gateway } = useContext(GatewayRoutesContext)

  const updateObject = async e => {
    e.preventDefault()
    setProcessing(true)
    await update(siteStore.id, store.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(addNotifications([successNotification("Store", t("The store has been updated."))]))
        setLoading(true)
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification("Store", message)]))
      })
      .finally(() => setProcessing(false))
  }

  const getConnectorInstances = async () => {
    setLoadingConnectorInstances(true)
    await paginatedRequest(`connectivity/sites/${siteStore.id}/connector_instances/?gateway=${gateway.id}`, auth.userData.access_token)()
      .then(results => {
        setConnectorInstances(results)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Store", message)]))
      })
      .finally(() => setLoadingConnectorInstances(false))
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (siteStore.id && organizationStore.id && store && store.id && gateway && gateway.id) {
      dispatch(
        updateBreadcrumb([
          { label: organizationStore.name, url: `/organizations/${organizationStore.id}/` },
          { label: t("Sites"), url: `/organizations/${organizationStore.id}/sites/` },
          {
            label: siteStore.pretty_name ?? siteStore.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/`
          },
          { label: t("Assets"), url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/` },
          {
            label: t("Gateways"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/`
          },
          {
            label: gateway.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/`
          },
          {
            label: t("Stores"),
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/stores/`
          },
          {
            label: store.name,
            url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/stores/${store.id}/`
          }
        ])
      )
    }
  }, [siteStore.id, organizationStore.id, store, gateway])

  useEffect(() => {
    if (store) {
      setData(store)
      setLoading(false)
    }
  }, [store])

  useEffect(() => {
    edit && getConnectorInstances()
  }, [edit])

  return (
    <>
      {store && (
        <>
          <div className="base-content-title">
            <h2>{store.name}</h2>
          </div>
          <OverView
            object={store}
            model={Store(connectorInstances, loadingConnectorInstances)}
            overviewModel={store && store.type === "gauge" ? StoreGaugeOverview() : StoreStatusOverview()}
            updateObject={updateObject}
            data={data}
            setData={setData}
            errors={errors}
            edit={edit}
            setEdit={setEdit}
            loading={loading}
            processing={processing}
            deleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/stores/${store.id}/delete/`}
          />
        </>
      )}
    </>
  )
}

import SiteRoutes from "/apps/assets/site/routes"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization as organizationStorage, updateOrganization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { createContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import { listSites, retrieve } from "./api"
import Create from "./components/Create"
import Delete from "./components/Delete"
import Detail from "./components/Detail"
import SitesMap from "./components/SitesMap"

const OrganizationRoutesContext = createContext(null)

const OrganizationContent = () => {
  const organizationStore = useSelector(organizationStorage)
  const auth = useAuth()
  const { organizationId } = useParams()
  const dispatch = useDispatch()
  const [organization, setOrganization] = useState()
  const [sites, setSites] = useState([])

  const retrieveDetail = async () => {
    await retrieve(organizationId, auth.userData.access_token)
      .then(response => {
        setOrganization(response.data)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Organizations", message)]))
      })
  }

  const retrieveSites = async () => {
    await listSites(organizationId, auth.userData.access_token)
      .then(({ data }) => {
        data.results && setSites(data.results)
      })
      .catch(error => {
        const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message
        dispatch(addNotifications([errorNotification("Organizations", message)]))
      })
  }

  useEffect(() => {
    if (organizationId) {
      retrieveDetail()
      retrieveSites()
    }
  }, [organizationId])

  useEffect(() => {
    if (organization && organization.id) {
      dispatch(updateSideBar({ menuName: "organizations", siteId: null }))
      dispatch(updateOrganization(organization))
    }
  }, [organization])

  return <OrganizationRoutesContext.Provider value={{ organization, sites, retrieveDetail }}>{organizationStore.id ? <Outlet /> : <Loader />}</OrganizationRoutesContext.Provider>
}

export { OrganizationRoutesContext }

export default function OrganizationRoutes() {
  return (
    <Routes>
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path=":organizationId/*"
        element={<OrganizationContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="map"
          element={<SitesMap />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
        <Route
          path="sites/*"
          element={<SiteRoutes />}
        />
      </Route>
    </Routes>
  )
}

import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"
import { commonsFields, commonsOverview } from "./commons"

export const OPCUANode = spaces => {
  return {
    ...commonsFields(spaces),
    ...{
      namespace_index: {
        label: i18n.t("Namespace index", { ns: "assets" }),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true,
          min: 0
        }
      },
      path: {
        label: i18n.t("Path", { ns: "assets" }),
        field: InputText,
        options: {}
      },
      batch_size_read: {
        label: i18n.t("Batch size read", { ns: "assets" }),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true,
          min: 1
        }
      },
      batch_size_write: {
        label: i18n.t("Batch size write", { ns: "assets" }),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true,
          min: 1
        }
      },
      security_policy: {
        label: i18n.t("Security policy", { ns: "assets" }),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "None", label: "None" },
            { value: "Basic256", label: "Basic256" },
            { value: "Basic128Rsa15", label: "Basic128Rsa15" },
            { value: "Basic256Sha256", label: "Basic256Sha256" }
          ]
        }
      },
      security_mode: {
        label: i18n.t("Security mode", { ns: "assets" }),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "None", label: "None" },
            { value: "Sign", label: "Sign" },
            { value: "SignAndEncrypt", label: "SignAndEncrypt" }
          ]
        }
      },
      server_certificate_path: {
        label: i18n.t("Server certificate path", { ns: "assets" }),
        field: InputText,
        options: {}
      }
    }
  }
}

export const OPCUAOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      namespace_index: { label: i18n.t("Namespace index", { ns: "assets" }) },
      path: { label: i18n.t("Path", { ns: "assets" }) },
      batch_size_read: { label: i18n.t("Batch size read", { ns: "assets" }) },
      batch_size_write: { label: i18n.t("Batch size write", { ns: "assets" }) },
      security_policy: { label: i18n.t("Security policy", { ns: "assets" }) },
      security_mode: { label: i18n.t("Security mode", { ns: "assets" }) },
      server_certificate_path: { label: i18n.t("Server certificate path", { ns: "assets" }) }
    }
  }
}

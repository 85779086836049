import { organization } from "/utils/store/organization"
import { TabsLayout } from "@software-engineering/hivolution-frontend-utils"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Route, Routes, useLocation, useParams } from "react-router-dom"
import ContractRoutes from "./contract/routes"
import EnergyDeliveryPointRoutes from "./energy-delivery-point/routes"

export default function EnergyRoutes() {
  const organizationStore = useSelector(organization)
  const location = useLocation()
  const { t } = useTranslation(["assets"])
  const { siteId } = useParams()
  const [tabs, setTabs] = useState([
    {
      name: t("Delivery points"),
      active: location.pathname.includes("points"),
      url: `/organizations/${organizationStore.id}/sites/${siteId}/energy/energy-delivery-points/`
    },
    {
      name: t("Contracts"),
      active: location.pathname.includes("contracts"),
      url: `/organizations/${organizationStore.id}/sites/${siteId}/energy/contracts/`
    }
  ])

  return (
    <Routes>
      <Route
        element={
          <TabsLayout
            title={t("Energy")}
            tabs={tabs}
            setTabs={setTabs}
          />
        }
      >
        <Route
          index
          path="energy-delivery-points/*"
          element={<EnergyDeliveryPointRoutes />}
        />
        <Route
          index
          path="contracts/*"
          element={<ContractRoutes />}
        />
      </Route>
    </Routes>
  )
}
